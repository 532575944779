import React from 'react';
import TextForm from '../components/PublicRelation';

const PublicRelationsPage = () => {
  return (
    <div>

      <TextForm />
    </div>
  );
};

export default PublicRelationsPage;
