import React, { useState } from "react";
import { FaFlag, FaBan, FaCopy } from "react-icons/fa"; // Icons
import { useNavigate, useParams } from "react-router-dom"; // For navigation and getting params
import axios from "axios"; // For making API requests

const ProfileModal = ({ onClose }) => {
  const navigate = useNavigate(); // Hook for navigation
  const { userId: userToBlock } = useParams(); // Get userToBlock from the URL
  const [loading, setLoading] = useState(false); // Loading state for block user action
  const user = JSON.parse(localStorage.getItem("user")); // Retrieve logged-in user data from local storage
  
  const handleReportProfile = () => {
    navigate("/report-page"); // Navigate to report page
  };

  const handleBlockUser = () => {
    if (!user || !user._id) {
      return alert("User is not logged in.");
    }
    setLoading(true); // Start loading
    axios
      .post(`/block-user/${userToBlock}`, { _id: user._id }) // Sending _id of the logged-in user
      .then((response) => {
        setLoading(false); // Stop loading
        alert("User has been successfully blocked!"); // Success message
        onClose(); // Close modal
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error blocking user:", error);
        alert("Failed to block user. Try again.");
      });
  };

  const handleCopyLink = () => {
    const profileLink = `${window.location.origin}/user-profile/${userToBlock}`; // Generate profile link
    navigator.clipboard.writeText(profileLink) // Copy the link to clipboard
      .then(() => {
        alert("Profile link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying link:", error);
        alert("Failed to copy profile link.");
      });
  };

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div style={styles.handle} /> {/* Modal drag handle */}
        <h2 style={styles.title}>Profile Options</h2>
        <div style={styles.option} onClick={handleReportProfile}>
          <FaFlag style={styles.icon} />
          <span>Report Profile</span>
        </div>
        <div style={styles.option} onClick={handleBlockUser}>
          {loading ? "Blocking..." : <><FaBan style={styles.icon} /><span>Block User</span></>}
        </div>
        <div style={styles.option} onClick={handleCopyLink}>
          <FaCopy style={styles.icon} />
          <span>Copy Link to Profile</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay background
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end", // Align modal to the bottom
    zIndex: 1000,
  },
  modal: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: "20px",
    boxShadow: "0 -4px 12px rgba(0, 0, 0, 0.1)",
    animation: "slide-up 0.4s ease",
  },
  handle: {
    width: "50px",
    height: "5px",
    backgroundColor: "#ccc",
    borderRadius: "5px",
    margin: "0 auto 20px auto",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    marginBottom: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#f5f5f5",
    transition: "background-color 0.3s ease",
  },
  optionHover: {
    backgroundColor: "#eee",
  },
  icon: {
    marginRight: "10px",
    fontSize: "1.2rem",
    color: "#007BFF",
  },
};

// Keyframes for modal slide-up animation
const slideUp = `
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export default ProfileModal;
