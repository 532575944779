import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/PhoneNumberVerification.css';

const PhoneNumberVerification = () => {
  const [pin, setPin] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(45);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const refs = useRef([]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer(prevTimer => prevTimer - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [resendTimer]);

  useEffect(() => {
    if (refs.current[0]) {
      refs.current[0].focus();
    }
  }, []);

  const handlePinChange = (index, value) => {
    // Check if the input is numeric
    if (/^\d*$/.test(value) && value.length === 1) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);
      if (index < 3) {
        refs.current[index + 1].focus();
      }
    }
  };

  const handleResendCode = async () => {
    try {
      const userKey = localStorage.getItem('user');
      if (userKey) {
        const userData = JSON.parse(userKey);
        const userId = userData._id;

        // Call endpoint to resend code with userId
        await axios.post('https://ampcds.onrender.com/api/auth/resend-code', { userId });
        // Update timer and reset PIN
        setResendTimer(45);
        setPin(['', '', '', '']);
        refs.current[0].focus();
      }
    } catch (error) {
      console.error('Error resending code:', error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const userKey = localStorage.getItem('user');
      if (userKey) {
        const userData = JSON.parse(userKey);
        const userId = userData._id;

        // Combine the pin digits into a single string
        const combinedPin = parseInt(pin.join(''), 10);

        // Call endpoint to verify pin with userId and pin
        await axios.post('https://ampcds.onrender.com/api/auth/verify', { userId, verificationCode: combinedPin });
        // If successful, redirect to login page
        navigate('/login');
      }
    } catch (error) {
      setErrorMessage(error.response ? error.response.data.error : 'Error verifying PIN.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="verification-container" style={{ marginTop:'90px' }}>
      <h2>ta-da! Let's get you Verified!</h2>
      <h5>Enter the 4 digit number sent to your phone number.</h5>
      <div className="pin-input-container">
        {pin.map((digit, index) => (
          <input
            key={index}
            type="text"
            pattern="[0-9]*"
            maxLength={1}
            value={digit}
            onChange={(e) => handlePinChange(index, e.target.value)}
            required
            ref={(el) => (refs.current[index] = el)}
            style={{ marginRight: '10px', width: '40px', textAlign: 'center' }}
          />
        ))}
      </div>
      <button
        className="submit-button"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Verifying...' : 'Submit'}
      </button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <p className="resend-code" onClick={handleResendCode}>
        Resend code ({resendTimer} secs)
      </p>
    </div>
  );
};

export default PhoneNumberVerification;
