import React from 'react';
import PostJob from '../components/PostJob'; // Assuming PostJob component is in the same directory

const PostJobPage = () => {
  return (
    <div>
      <PostJob />
    </div>
  );
};

export default PostJobPage;
