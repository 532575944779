import WithdrawComponent from '../components/WithdrawComponent';

const WithdrawalsPage = () => {
  return (
    <div>
      <WithdrawComponent />
    </div>
  );
};

export default WithdrawalsPage;
