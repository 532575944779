import React from 'react';
import TermsAndConditions from '../components/TermsAndConditions';
import MarketplaceGuidelines from '../components/MarketplaceGuidelines';

const TermsAndConditionsPage = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="terms-page-container">
      
      <section id="terms">
        <TermsAndConditions />
      </section>

      <section id="guidelines">
        <MarketplaceGuidelines />
      </section>

      <p className="back-to-top" onClick={() => scrollToSection('top')}>
        Back to Top
      </p>
    </div>
  );
};

export default TermsAndConditionsPage;
