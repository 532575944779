import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/ServicemenList.css';

const ServicemenList = () => {
  const [servicemen, setServicemen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    const fetchServicemen = async () => {
      try {
        const localServicemen = localStorage.getItem('irunor-servicemen');
        
        // If servicemen data exists in local storage, use it for rendering
        if (localServicemen) {
          setServicemen(JSON.parse(localServicemen));
          setLoading(false); // Avoid showing loading when we have local data
        }

        // Fetch new servicemen data from the API
        const servicemenResponse = await axios.get('https://ampcds.onrender.com/api/auth/servicemen');
        if (servicemenResponse.data && servicemenResponse.data.servicemen) {
          const updatedServicemen = servicemenResponse.data.servicemen.map(serviceman => ({
            ...serviceman,
            displayName: serviceman.businessName || serviceman.fullName,
          }));

          // Update state and local storage
          setServicemen(updatedServicemen);
          localStorage.setItem('irunor-servicemen', JSON.stringify(updatedServicemen));
        } else {
          throw new Error("Invalid data format");
        }
      } catch (err) {
        setError('Failed to fetch servicemen');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServicemen();
  }, []);

  const handleServicemanClick = (serviceman) => {
    // Redirect to the profile page using the userId from the backend response
    navigate(`/user-profile/${serviceman.userId}`);
  };

  if (loading && servicemen.length === 0) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!servicemen || servicemen.length === 0) {
    return <div className="no-servicemen">No servicemen available.</div>;
  }

  return (
    <div className="servicemen-list">
      <ul className="servicemen">
        {servicemen.map(serviceman => (
          <li 
            key={serviceman._id} 
            className="serviceman-card" 
            onClick={() => handleServicemanClick(serviceman)}
          >
            <div className="service-circle">{serviceman.serviceRendered}</div>
            <p className="serviceman-name-preview">{serviceman.displayName}</p>
            <p className="serviceman-description">
              {serviceman.serviceDescription?.length > 100
                ? `${serviceman.serviceDescription.slice(0, 100)}...`
                : serviceman.serviceDescription}
            </p>
            <div className="card-footer">
              <span className="serviceman-location">{serviceman.currentLocation}</span>
              <span className="serviceman-rating">
                {'★'.repeat(5)} {/* Default to 5 stars */}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServicemenList;
