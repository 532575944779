import React from 'react';
import ConfirmSubscription from '../components/BusinessConfirmation';

const SubConfirmations = () => {
  return (
    <div >
      <ConfirmSubscription />
    </div>
  );
};

export default SubConfirmations;
