import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UnitsComponent from '../components/UnitsComponents';

const PostJob = () => {
  const [jobDetails, setJobDetails] = useState({
    description: '',
    maxBudget: '',
    minBudget: '',
    phoneNumber: '',
    userId: '',
    fullName: '',
    location: '',
    mediaUrl: '', // To store the URL of the uploaded media
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showUnitsComponent, setShowUnitsComponent] = useState(false);
  const [mediaPreview, setMediaPreview] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedDraft = localStorage.getItem('draft');
    if (storedDraft) {
      setJobDetails(JSON.parse(storedDraft));
    }
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      const { phoneNumber, _id: userId, fullName, privateName } = userData;
      const nameToUse = privateName || fullName;
      setJobDetails((prevJobDetails) => ({
        ...prevJobDetails,
        phoneNumber,
        userId,
        fullName: nameToUse,
      }));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('draft', JSON.stringify(jobDetails));
  }, [jobDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMediaUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setJobDetails((prevDetails) => ({
          ...prevDetails,
          mediaUrl: reader.result, // Convert the file to a data URL
        }));
        setMediaPreview(reader.result); // Set the preview
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(localStorage.getItem('user'));
    const runorUnits = userData ? userData.runorUnits : 0;

    if (parseInt(runorUnits) >= parseInt(jobDetails.maxBudget)) {
      try {
        setIsSubmitting(true);
        const response = await axios.post('https://ampcds.onrender.com/jobs/post-job', jobDetails);
        navigate('/my-jobs');
      } catch (error) {
        console.error('Error submitting job:', error);
        setError('uh-oh, network error. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setShowUnitsComponent(true);
    }
  };

  const handleCloseUnitsComponent = () => {
    setShowUnitsComponent(false);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Create Your Job</h2>
      <p style={styles.subText}>
        Need something done? Describe it, set a budget, and let us handle the rest. 
        Add a visual or video to make your request even clearer! 🚀
      </p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <textarea
          name="description"
          value={jobDetails.description}
          onChange={handleChange}
          placeholder="Describe your job (e.g., tasks, special instructions)"
          required
          style={styles.textArea}
        />

        <div style={styles.budgetContainer}>
          <input
            type="number"
            name="maxBudget"
            value={jobDetails.maxBudget}
            onChange={handleChange}
            placeholder="Max Budget"
            required
            style={styles.input}
          />
          <input
            type="number"
            name="minBudget"
            value={jobDetails.minBudget}
            onChange={handleChange}
            placeholder="Min Budget"
            style={styles.input}
          />
        </div>

        <input
          type="text"
          name="location"
          value={jobDetails.location}
          onChange={handleChange}
          placeholder="Location"
          style={styles.inputFullWidth}
        />

        <div style={styles.mediaContainer}>
          <label htmlFor="mediaUpload" style={styles.mediaLabel}>
            Upload Image/Video:
          </label>
          <input
            type="file"
            accept="image/*, video/*"
            onChange={handleMediaUpload}
            id="mediaUpload"
            style={styles.mediaInput}
          />
        </div>

        {mediaPreview && (
          <div style={styles.mediaPreviewContainer}>
            {jobDetails.mediaUrl.includes('video') ? (
              <video controls style={styles.mediaPreview}>
                <source src={mediaPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={mediaPreview} alt="Preview" style={styles.mediaPreview} />
            )}
          </div>
        )}

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isSubmitting && styles.buttonDisabled),
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Job'}
        </button>

        {error && <p style={styles.errorMessage}>{error}</p>}
      </form>

      {showUnitsComponent && (
        <div style={styles.unitsComponentContainer}>
          <UnitsComponent onClose={handleCloseUnitsComponent} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '90%',
    margin: '90px auto',
    padding: '20px',
    maxWidth: '600px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  subText: {
    textAlign: 'center',
    color: '#666',
    marginBottom: '20px',
    fontSize: '16px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textArea: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    minHeight: '100px',
    resize: 'vertical',
    marginBottom: '15px',
  },
  budgetContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  input: {
    width: '48%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  inputFullWidth: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '15px',
  },
  mediaContainer: {
    marginBottom: '15px',
  },
  mediaLabel: {
    display: 'block',
    marginBottom: '8px',
    color: '#333',
    fontWeight: 'bold',
  },
  mediaInput: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    width: '100%',
  },
  mediaPreviewContainer: {
    marginTop: '15px',
    textAlign: 'center',
  },
  mediaPreview: {
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  button: {
    width: '100%',
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  buttonDisabled: {
    backgroundColor: '#aaa',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginTop: '15px',
  },
  unitsComponentContainer: {
    marginTop: '20px',
  },
};

export default PostJob;
