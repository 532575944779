import React from 'react';
import Foundation from '../components/Foundations';

const FoundationPage = () => {
  return (
    <div>
      <Foundation />
      <div style={{ margin: '50px auto', width: '80%', textAlign: 'left', marginTop: '10px' }}> 
    </div>
    </div>
    
  );
};

export default FoundationPage;
