import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmingSubscription = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const confirmSubscription = async () => {
      try {
        // Retrieve the user object from localStorage
        const user = JSON.parse(localStorage.getItem('user'));

        // Check if user exists and has _id field
        if (user && user._id) {

          const response = await fetch('https://ampcds.onrender.com/api/auth/update-subscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user._id }),
          });

          if (response.ok) {
            // Simulate a 45-second process (replace with actual subscription confirmation logic)
            await new Promise(resolve => setTimeout(resolve, 45000));

            // Once process is complete, redirect to job page
            navigate('/business-centre');
          } else {
            console.error('Failed to update subscription status');
            // Redirect to error page if subscription update fails
            navigate('/error-page');
          }
        } else {
          console.error('User not found or _id field missing');
          // Redirect to home or login page if user is not found or _id field is missing
          navigate('/');
        }
      } catch (error) {
        console.error('Error confirming subscription:', error);
        // Redirect to error page if there is an error during subscription confirmation
        navigate('/error-page');
      }
    };

    confirmSubscription();
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop:'90px' }}>
      <h2>Confirming Subscription</h2>
      <p>This may take up to 45 seconds...</p>
      <p>Please wait, you will be redirected once the process is complete.</p>
    </div>
  );
};

export default ConfirmingSubscription;
