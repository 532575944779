import React from 'react';
import PasswordReset from '../components/PasswordReset'; // Adjust the path if needed

const SleekPasswordResetPage = () => {
  return (
    <div style={pageStyles.container}>
      <PasswordReset />
    </div>
  );
};

const pageStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#e0e0e0',
  },
};

export default SleekPasswordResetPage;
