import React from 'react';
import AboutUs from '../components/AboutUs'; // Import the AboutUs component

const AboutUsPage = () => {
  return (
    <div>
      <AboutUs /> {/* Render the AboutUs component */}
    </div>
  );
};

export default AboutUsPage;
