import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCircle, FaEllipsisH } from "react-icons/fa"; // Import icons
import { useParams, useNavigate } from "react-router-dom";
import ProfileModal from "../components/ProfileModal"; // Modal component for the menu
import "../styles/GeneralProfile.css"; // Import external CSS

const ProfileCard = () => {
  const { userId } = useParams(); // Get userId from the URL
  const [user, setUser] = useState({
    serviceRendered: "...",
    fullName: "...",
    businessName: "",
    openings: "...",
    completions: "...",
    serviceDescription: "........",
    phoneNumber: "",
    currentLocation: "",
  });
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [modalVisible, setModalVisible] = useState(false); // Modal state for the menu

  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    // Fetch user data
    axios
      .get(`https://ampcds.onrender.com/jobs/user-profile/${userId}`)
      .then((response) => {
        const {
          serviceRendered,
          fullName,
          businessName,
          openings,
          completions,
          serviceDescription,
          phoneNumber,
          currentLocation,
        } = response.data;

        setUser({
          serviceRendered: serviceRendered || "Employer",
          fullName: fullName || "John Doe",
          businessName: businessName || "",
          openings: openings || 0,
          completions: completions || 0,
          serviceDescription:
            serviceDescription || "We will open soon.",
          phoneNumber: phoneNumber || "",
          currentLocation: currentLocation || "",
        });
        setLoading(false); // Data fetched, stop loading
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, [userId]);

  const checkUserLoggedIn = (callback) => {
    const userData = localStorage.getItem("user");
    if (!userData) {
      // If user not logged in, redirect to login page
      navigate("/login", { state: { from: `/profile/${userId}` } });
    } else {
      // Proceed with the callback (either call or pay)
      callback();
    }
  };

  const handleHireNow = () => {
    checkUserLoggedIn(() => {
      window.open(`tel:${user.phoneNumber}`);
    });
  };

  const handlePay = () => {
    checkUserLoggedIn(() => {
      navigator.clipboard.writeText(user.phoneNumber);
      alert("Phone number copied!");
      window.location.href = "/profile";
    });
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible); // Toggle modal visibility
  };

  // Use businessName if available, otherwise fullName
  const displayName = user.businessName ? user.businessName : user.fullName;

  return (
    <div className="container">
      {loading ? (
        <div className="apple-spinner-container">
          <div className="apple-spinner"></div>
        </div>
      ) : (
        <>
          {/* Profile Section */}
          <div className="profileSection">
            <FaUserCircle className="profileIcon" />
            <div className="userInfo">
              <div className="fullName">{displayName}</div>
              <div className="serviceRendered">{user.serviceRendered}@{user.currentLocation}</div>
            </div>
          </div>

          {/* Stats */}
          <div className="stats">
            <div className="statItem">
              <strong>{user.openings}</strong>
              <span>Openings</span>
            </div>
            <div className="statItem">
              <strong>{user.completions}</strong>
              <span>Completions</span>
            </div>
          </div>

          {/* Service Description */}
          <div className="serviceDescription">{user.serviceDescription}</div>

          {showPhoneNumber && (
            <div className="phoneNumber">{user.phoneNumber}</div>
          )}

          {/* Buttons */}
          <div className="buttonContainer">
            <button className="primaryButton" onClick={handleHireNow}>
              Call to Hire
            </button>
            <button className="secondaryButton" onClick={handlePay}>
              ₦ Pay Now
            </button>
            <FaEllipsisH className="menuIcon" onClick={toggleModal} />
          </div>

          {modalVisible && <ProfileModal onClose={toggleModal} />} {/* Modal */}
        </>
      )}
    </div>
  );
};

export default ProfileCard;
