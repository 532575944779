import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navigation.css';

const Navigation = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (menuVisible && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    const storedUser = localStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    setUser(parsedUser);
    setIsLoggedIn(!!(parsedUser && parsedUser.accountNumber));

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [menuVisible]);

  return (
    <nav className="navigation-container" ref={menuRef}>
      <div className="menu-icon" onClick={() => setMenuVisible(!menuVisible)}>
        &#9776;
      </div>
      <ul className={`menu-items ${menuVisible ? 'visible' : ''}`}>
        {isLoggedIn ? (
          <>
            <Link to="/" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li>Home</li>
            </Link>
            <Link to="/upgrade-page" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li>Pricing</li>
            </Link>

            {(user?.phoneNumber === '08106292993' || user?.phoneNumber === '08108149145') && (
              <Link to={`/accounting/${user._id}`} className="menu-list" onClick={() => setMenuVisible(false)}>
                <li>Accounting</li>
              </Link>
            )}
            {(user?.phoneNumber === '08106292993' || user?.phoneNumber === '08088274805') && (
              <Link to="/public-relation" className="menu-list" onClick={() => setMenuVisible(false)}>
                <li>Public Relations</li>
              </Link>
            )}
            {(user?.phoneNumber === '08106292993' || user?.phoneNumber === '08088274805') && (
              <Link to="/team-chat" className="menu-list" onClick={() => setMenuVisible(false)}>
                <li>Team Chat</li>
              </Link>
            )}
            {user?.teamKey && (
              <Link to="/e-office" className="menu-list" onClick={() => setMenuVisible(false)}>
                <li>E-Office</li>
              </Link>
            )}
          </>
        ) : (
          <>
            <Link to="/home" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li>Runor</li>
            </Link>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
