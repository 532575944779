import React from 'react';
import UpgradeToUnlimitedCalls from '../components/Subs'; // Import the AboutUs component
import BusinessPlan from '../components/BusinessPlan';
import { Link } from 'react-router-dom';


const UpgradePage = () => {
  return (
    <div  >
     <h2 style={{  width: '100%', marginLeft: '20px', marginTop: '90px' }}>Your First 5 Calls is Free!🎉</h2>
      <UpgradeToUnlimitedCalls /> {/* Render the AboutUs component */}
      <BusinessPlan />
      <p style={{ marginLeft: '20px'}}>For every subscription, irunor tax deductible donates to <Link to="/foundation" className="foundation-link">Foundations</Link>.</p>

    </div>
  );
};

export default UpgradePage;
