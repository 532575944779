import React from 'react';
import JobDetails from '../components/JobDetails'; // Import the AboutUs component

const JobDetailsPage = () => {
  return (
    <div>
      <JobDetails /> 

      {/* Render the AboutUs component */}
    </div>
  );
};

export default JobDetailsPage;
