import React from 'react';

// Function to parse and format text
const parseText = (text) => {
  // Split text into paragraphs
  const paragraphs = text.split(/\n\s*\n/);

  return paragraphs.map((paragraph, index) => {
    // Heading detection
    if (/^#{1,6}\s/.test(paragraph)) {
      const [headingLevel, ...rest] = paragraph.split(/\s+/);
      const level = headingLevel.length;
      const headingText = rest.join(' ');
      return React.createElement(`h${level}`, { key: index }, headingText);
    }
    // Blockquote detection
    else if (/^>/.test(paragraph)) {
      const blockquoteText = paragraph.replace(/^>\s*/, '');
      return <blockquote key={index}>{parseText(blockquoteText)}</blockquote>;
    }
    // Code block detection
    else if (/^```/.test(paragraph)) {
      const [codeBlock] = paragraph.split(/```/).slice(1);
      return <pre key={index}><code>{codeBlock}</code></pre>;
    }
    // List detection (ordered and unordered)
    else if (/^\d+\.\s/.test(paragraph)) {
      const listItems = paragraph.split(/\n/).map((item, i) => (
        <li key={i}>{item.replace(/^\d+\.\s*/, '')}</li>
      ));
      return <ol key={index}>{listItems}</ol>;
    }
    else if (/^\s*-\s/.test(paragraph)) {
      const listItems = paragraph.split(/\n/).map((item, i) => (
        <li key={i}>{item.replace(/^\s*-\s*/, '')}</li>
      ));
      return <ul key={index}>{listItems}</ul>;
    }
    // Bullet points with bold text detection
    else if (/^\s*•\s*\*\*(.*?)\*\*/.test(paragraph)) {
      const listItems = paragraph.split(/\n/).map((item, i) => {
        const match = /^\s*•\s*\*\*(.*?)\*\*/.exec(item);
        if (match) {
          const boldText = match[1];
          return <li key={i}><strong>{boldText}</strong></li>;
        }
        return null;
      }).filter(Boolean);
      return <ul key={index}>{listItems}</ul>;
    }
    // Link detection
    else if (/\[.*?\].*?/.test(paragraph)) {
      const linkText = paragraph.replace(/\[(.*?)\].*?/g, (match, p1) => p1);
      const linkHref = paragraph.match(/(.*?)/)[1];
      return <a href={linkHref} key={index}>{linkText}</a>;
    }
    // Bold and italic text detection
    else {
      const formattedText = paragraph
        .split(/(\*\*.*?\*\*|\*.*?\*)/)
        .map((part, i) => {
          if (/^\*\*(.*?)\*\*/.test(part)) {
            return <strong key={i}>{part.replace(/\*\*/g, '')}</strong>;
          } else if (/^\*(.*?)\*$/.test(part)) {
            return <em key={i}>{part.replace(/\*/g, '')}</em>;
          } else {
            return <span key={i}>{part}</span>;
          }
        });
      return <p key={index}>{formattedText}</p>;
    }
  });
};

const FormatText = ({ text }) => {
  return <div>{parseText(text)}</div>;
};

export default FormatText;
