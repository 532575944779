import React from 'react';
import LinkBankAccount from '../components/linkBank'; // Adjust the path based on your file structure

const LinkBankAccountPage = () => {
  return (
    <div>
      <h1>Bank Account Linking</h1>
      <LinkBankAccount />
    </div>
  );
};

export default LinkBankAccountPage;
