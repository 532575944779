import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const LeadershipFocus = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://ampcds.onrender.com/jobs/blogs');
        // Sort blogs by date, latest first
        const sortedBlogs = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setBlogs(sortedBlogs);
        setLoading(false);
        localStorage.setItem('blogs', JSON.stringify(sortedBlogs)); // Save sorted blogs to local storage
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div style={{ margin: '50px auto', width: '90%', textAlign: 'left', marginLeft: '10px', marginTop: '90px' }}>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold', marginBottom: '30px', color: '#333' }}>Our Leadership Resolve - Beyond and Above</h2>
      {loading ? (
        <p>Loading blogs...</p>
      ) : (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {blogs.map((blog) => (
            <li key={blog._id} style={{ marginBottom: '40px' }}>
              <Link
                to={{
                  pathname: `/leadership-focus/${blog._id}`,
                  state: { blogId: blog._id } // Pass blog id as state
                }}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px', color: '#007AFF', textAlign: 'left' }}>{blog.title}</h2>
                <p style={{ fontSize: '16px', color: '#666', marginBottom: 0 }}>{blog.body.substring(0, 100)}...</p>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LeadershipFocus;
