import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/PrivateName.css'; // CSS file for styling

const FloatingComponent = () => {
    const [privateNameInput, setPrivateNameInput] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [serverResponse, setServerResponse] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [visible, setVisible] = useState(false);

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const userId = user ? user._id : null;

    // Control component visibility based on local storage
    useEffect(() => {
        if (user && user.privateNameValue === false) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [user]);

    const handleSubmit = async () => {
        try {
            // Validate input
            const errors = [];
            if (!privateNameInput.trim()) {
                errors.push('Private name cannot be empty');
            }
            if (/\s/.test(privateNameInput)) {
                errors.push('No spaces allowed');
            }
            if (/[^\w\s]/.test(privateNameInput)) {
                errors.push('Currency symbols are not allowed');
            }

            if (errors.length > 0) {
                setErrorMessages(errors);
                return;
            }

            setSubmitting(true);

            const requestData = {
                userId,
                privateName: privateNameInput,
            };

            const response = await axios.post(
                'https://ampcds.onrender.com/api/auth/setPrivateName',
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const data = response.data;

            setServerResponse(data.message);

            // Update local storage to indicate privateName is set
            const updatedUser = { ...user, privateNameValue: true };
            localStorage.setItem('user', JSON.stringify(updatedUser));

            // Hide the component after successfully setting private name
            setVisible(false);
        } catch (error) {
            console.error('Error:', error);

            if (error.response) {
                console.log('Response Error:', error.response);
            }

            setServerResponse('Error: Failed to set private name');
        } finally {
            setSubmitting(false);
        }
    };

    const handleClose = () => {
        // Set visible to false to hide the component
        setVisible(false);
        // Update local storage to indicate privateName is set
        const updatedUser = { ...user, privateNameValue: true };
        localStorage.setItem('user', JSON.stringify(updatedUser));
    };

    const handleInputFocus = () => {
        setExpanded(true);
    };

    // Only render the component when visible is true
    if (!visible) {
        return null;
    }

    return (
        <div className={`floating-component ${expanded ? 'expanded' : ''}`}>
            <h1>Privacy</h1>
            <p>Post Jobs anonymously.</p>
            <input
                type="text"
                value={privateNameInput}
                onChange={(e) => setPrivateNameInput(e.target.value.replace(/\s+/g, '').toLowerCase())}
                onFocus={handleInputFocus}
                placeholder="Enter_private_name"
                maxLength={20}
                className="input-field"
            />
            {errorMessages.length > 0 && (
                <ul className="error-list">
                    {errorMessages.map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            )}
            <button
                onClick={handleSubmit}
                className="submit-button"
                disabled={submitting}
            >
                {submitting ? 'Submitting...' : 'Submit'}
            </button>
            <p>Learn more about how we handle your privacy</p>

            <div className="close-icon" onClick={handleClose}>X</div>
            {serverResponse && <p className="server-response">{serverResponse}</p>}
        </div>
    );
};

export default FloatingComponent;
