import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import istockphoto from '../assets/images/istockphoto-1.jpg'; // Import the image
import '../styles/Home.css'; // Import the CSS file for styling

const HomeComponent = () => {
  const navigate = useNavigate();

  // Function to send AJAX request to keep server alive
  const keepServerAlive = () => {
    fetch('https://ampcds.onrender.com/api/auth/keep-alive')
      .then(response => {
        if (response.ok) {
          console.log('Server is alive');
        } else {
          console.error('Error keeping server alive:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error keeping server alive:', error);
      });
  };

  // Call keepServerAlive function every 5 minutes
  useEffect(() => {
    const intervalId = setInterval(keepServerAlive, 300000); // 300000 milliseconds = 5 minutes

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleLoginClick = () => {
    navigate('/signup'); // Redirect to the login page
  };

  const handleSignupClick = () => {
    navigate('/about-us'); // Redirect to the signup page
  };

  return (
    <div className="home-container">
      <img src={istockphoto} alt="Hero" className="hero-image" />
      <div className="overlay">
        <h1 className="overlay-text">
          Hire the World, Anytime.
        </h1>
        <p className="overlay-description">
          Your opportunity to hire the world, no long term contracts, no commitment. Pay per job.
        </p>
        <div className="button-container">
          <button className="styled-button primary" onClick={handleLoginClick}>
            Get Started
          </button>
          <button className="styled-button secondary" onClick={handleSignupClick}>
            Learn More
          </button>
        </div>
        <h5 className="legacy-text">Formerly a runor experiment</h5>
      </div>
    </div>
  );
};

export default HomeComponent;
