// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const root = document.getElementById('root');

// Use createRoot() without the redundant ReactDOM
const reactRoot = createRoot(root);
reactRoot.render(<React.StrictMode><App /></React.StrictMode>);
