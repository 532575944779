import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/UserJobs.css';

const UserJobs = () => {
  const [userJobs, setUserJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (!userData) {
      return;
    }

    try {
      const { _id } = JSON.parse(userData);

      axios.get('https://ampcds.onrender.com/jobs/user-jobs', {
        params: {
          userId: _id
        }
      })
        .then(response => {
          // No filtering of taken jobs here, display all jobs
          setUserJobs(response.data);
          setIsLoading(false);
        })
        .catch(error => console.error('Error fetching user jobs:', error));
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }, []);

  const handlePauseResumeClick = async (jobId, taken) => {
    const endpoint = taken
      ? `https://ampcds.onrender.com/jobs/not-taken-job/${jobId}`  // Mark as not taken (resume)
      : `https://ampcds.onrender.com/jobs/taken-job/${jobId}`;    // Mark as taken (pause)

    try {
      await axios.post(endpoint);
      setUserJobs(prevJobs =>
        prevJobs.map(job =>
          job._id === jobId ? { ...job, taken: !taken } : job
        )
      );
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const handleHideClick = (jobId) => {
    navigate(`/callers-page/${jobId}`);
  };

  return (
    <div className="user-jobs-container" style={{ marginBottom: '90px' }}>
      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : userJobs.length === 0 ? (
        <div className="no-jobs-message">
          <p>You are yet to create a job.</p>
          <Link to="/post-job" className="get-started-link">Get started</Link>
        </div>
      ) : (
        userJobs.map(job => (
          <div key={job._id} className="job-card">
            <h3>{job.callCount} Servicemen called you.</h3>
            <p>{job.description}</p>
            <p>Budget: ₦{job.minBudget} - ₦{job.maxBudget}</p>

            <div className="button-container">
              <button
                style={{
                  marginRight: '10px',
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  color: "black",
                  width: '100px'
                }}
                onClick={() => handlePauseResumeClick(job._id, job.taken)}
              >
                {job.taken ? 'Resume' : 'Pause'} {/* Show Resume if taken, Pause if not */}
              </button>
              <button onClick={() => handleHideClick(job._id)}>Applicants</button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UserJobs;
