// Login.jsx
import React from 'react';
import UserJobs from '../components/PostedJobs';

const PostedJobPage = () => {

  return (
    <div >
      <UserJobs />
    </div>
  );
};

export default PostedJobPage;
