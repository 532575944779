import React, { useState } from 'react';

const PermissionRequestPage = ({ permission, onRequestPermission }) => {
  const [permissionGranted, setPermissionGranted] = useState(null);

  const handleRequestPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: permission });
      if (permissionStatus.state === 'granted') {
        setPermissionGranted(true);
        onRequestPermission(true);
      } else {
        setPermissionGranted(false);
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
      setPermissionGranted(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Permission Request</h2>
      <p>This app requires access to {permission} to proceed.</p>
      {permissionGranted === null && (
        <button onClick={handleRequestPermission}>Request Permission</button>
      )}
      {permissionGranted === true && (
        <p>Permission granted. Redirecting...</p>
      )}
      {permissionGranted === false && (
        <p>Permission denied. Please enable {permission} in your device settings.</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
  },
};

export default PermissionRequestPage;
