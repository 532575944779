import React from 'react';
import { Link } from 'react-router-dom';

const ProfileContainer = ({ isVisible, menuRef, handleLogout, isLoggedIn, setProfileVisible }) => {
    // Retrieve the user data from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    const isSubscribed = user?.isSubscribed;

    return (
        <div
            className={`profile-div ${isVisible ? 'visible' : ''}`}
            ref={menuRef}
            tabIndex={-1}
            onBlur={() => setProfileVisible(false)}
        >
            <ul className="profile-items">
                {isLoggedIn ? (
                    <>
                        <Link to="/profile" onClick={() => setProfileVisible(false)}>
                            <li className="profile-list">Profile</li>
                        </Link>
                        <Link to="/link-bank-account" onClick={() => setProfileVisible(false)}>
                            <li className="profile-list">Bank</li>
                        </Link>
                        <Link to="/my-jobs" className="menu-list" onClick={() => setProfileVisible(false)}>
                            <li>My Jobs</li>
                        </Link>
                        {/* Conditional rendering for Business Centre */}
                        {isSubscribed && (
                            <Link to="/business-centre" onClick={() => setProfileVisible(false)}>
                                <li className="profile-list">Business Centre</li>
                            </Link>
                        )}
                        <li className="profile-list-alone" onClick={handleLogout}>
                            Sign Out
                        </li>
                    </>
                ) : (
                    <>
                        <Link to="/signup" onClick={() => setProfileVisible(false)}>
                            <li className="profile-list">Create Account</li>
                        </Link>
                        <Link to="/leadership-focus" onClick={() => setProfileVisible(false)}>
                            <li className="profile-list">Updates</li>
                        </Link>
                        <Link to="/upgrade-page" onClick={() => setProfileVisible(false)}>
                            <li className="profile-list">Upgrade</li>
                        </Link>
                    </>
                )}
            </ul>
        </div>
    );
};

export default ProfileContainer;
