import React from 'react';
import '../styles/MarketplaceGuidelines.css';

const MarketplaceGuidelines = () => {
    console.log('Rendering Marketplace Guidelines');

  return (
    <div className="guidelines-container">
      <h1>Marketplace Guidelines</h1>
      <p>
        Welcome to our vibrant community! To ensure a positive experience for
        everyone, we’ve established a set of guidelines to help you navigate and
        contribute to our platform responsibly. By participating, you agree to
        abide by these guidelines, fostering a respectful and collaborative
        environment.
      </p>

      <h2>1. Respect and Professionalism</h2>
      <p>
        Treat everyone with respect, regardless of their background, expertise,
        or opinions. Engage in professional and courteous communication. Harassment,
        discrimination, or any form of disrespectful behavior will not be tolerated.
      </p>

      <h2>2. Honesty and Integrity</h2>
      <p>
        Be honest and transparent in your interactions. Misrepresentation, fraud,
        or deceitful practices are strictly prohibited. Uphold the highest standards
        of integrity in all your dealings on the platform.
      </p>

      <h2>3. Fairness and Equality</h2>
      <p>
        Ensure that your transactions and agreements are fair and equitable to all
        parties involved. Avoid exploiting others or taking advantage of the platform
        for unethical gain. Everyone deserves an equal opportunity to succeed here.
      </p>

      <h2>4. Legal Compliance</h2>
      <p>
        All activities on the platform must comply with local, national, and
        international laws. This includes respecting intellectual property rights,
        honoring contracts, and adhering to regulations applicable to your industry.
      </p>

      <h2>5. Quality and Excellence</h2>
      <p>
        Strive for excellence in everything you do. Whether you're offering a service,
        sharing content, or collaborating with others, always aim to deliver high-quality
        work. Your reputation and the reputation of the platform depend on it.
      </p>

      <h2>6. Privacy and Confidentiality</h2>
      <p>
        Respect the privacy and confidentiality of others. Do not share personal or
        sensitive information without explicit consent. Protect your own privacy by
        being cautious about the information you disclose on the platform.
      </p>

      <h2>7. Collaboration and Community</h2>
      <p>
        Contribute to a collaborative and supportive community. Share knowledge,
        offer help, and encourage others to grow. A thriving community benefits
        everyone, so let's build each other up.
      </p>

      <h2>8. Reporting Violations</h2>
      <p>
        If you encounter behavior that violates these guidelines, please report it
        immediately through our <a href="/support">Support Page</a>. We take all
        reports seriously and will take appropriate action to maintain the integrity
        of our platform.
      </p>

      <p className="final-note">
        By following these guidelines, you help create a positive, safe, and
        successful environment for everyone. Let’s work together to make this
        marketplace a place where innovation and collaboration thrive.
      </p>
    </div>
  );
};

export default MarketplaceGuidelines;
