import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import ProfileContainer from '../components/ProfileContainer';
import '../styles/ProfileIcon.css';

const ProfileIcon = () => {
    const [profileVisible, setProfileVisible] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const profileRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            // If the profile container is visible and the clicked target is outside the profileRef
            if (profileVisible && profileRef.current && !profileRef.current.contains(event.target)) {
                setProfileVisible(false);
            }
        };

        // Retrieve user data from localStorage
        const storedUser = localStorage.getItem('user');
        const parsedUser = storedUser ? JSON.parse(storedUser) : null;
        setIsLoggedIn(!!(parsedUser && parsedUser.accountNumber));

        // Add the document click event listener
        document.addEventListener('click', handleDocumentClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [profileVisible]);

    const handleLogout = () => {
        localStorage.clear();
        setProfileVisible(false);
        navigate('/login');
    };

    const handleProfileClick = () => {
        setProfileVisible((prevProfileVisible) => !prevProfileVisible);
    };

    return (
        <nav className="profile-container">
            <div className="profile-icon" onClick={handleProfileClick}>
                <FaUserCircle className="profile-icon" />
            </div>
            <ProfileContainer
                isVisible={profileVisible}
                profileRef={profileRef}
                handleLogout={handleLogout}
                isLoggedIn={isLoggedIn}
                setProfileVisible={setProfileVisible}
            />
        </nav>
    );
};

export default ProfileIcon;
