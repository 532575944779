function Foundation() {
    return (
      <div style={{ marginTop: '15px', marginLeft: '10px'}}>
       <h1>Resource: Foundation</h1>
Are you a foundation? Register for this fund today! <a> click here</a>   
        <h1>How the Funds is split between organisations</h1>
        <p>Current Members of the Fund</p>

   </div>
    );
  }
  
  export default Foundation;
  