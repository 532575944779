import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, CircularProgress, Select, MenuItem, InputLabel, FormControl, IconButton, Card, CardContent, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const LinkBankAccount = () => {
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [linked, setLinked] = useState(false);
  const [savedBankInfo, setSavedBankInfo] = useState(null);

  useEffect(() => {
    // Fetch the list of banks when the component mounts
    axios.get('https://ampcds.onrender.com/api/banks')
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          const sortedBanks = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
          setBanks(sortedBanks);
        }
      })
      .catch(error => console.error('Error fetching banks:', error));

    // Attempt to fetch user's saved bank info from the server
    const userStorage = localStorage.getItem('user');
    let userId = '';

    if (userStorage) {
      const user = JSON.parse(userStorage);
      userId = user._id || '';
    }

    if (userId) {
      axios.get(`https://ampcds.onrender.com/api/api/user-bank/${userId}`)
        .then(response => {
          if (response.data && response.data.bank_info) {
            const { account_bank, account_number, account_name } = response.data.bank_info;
            setSavedBankInfo({ account_bank, account_number, account_name });
            setSelectedBank(account_bank);
            setAccountNumber(account_number);
            setAccountName(account_name);
            setLinked(true); // Automatically link if bank info is found
          }
        })
        .catch(error => console.error('Error fetching user bank info:', error));
    }
  }, []);

  const handleAccountNumberChange = (e) => {
    const number = e.target.value;
    setAccountNumber(number);

    if (number.length === 10 && selectedBank) {
      setLoading(true);
      axios.post('https://ampcds.onrender.com/api/verify-account', {
        account_number: number,
        account_bank: selectedBank,
      })
        .then(response => {
          if (response.data && response.data.data && response.data.data.account_name) {
            setAccountName(response.data.data.account_name);
          } else {
            setAccountName('');
          }
          setLoading(false);
        })
        .catch(error => {
          setAccountName('');
          setLoading(false);
        });
    }
  };

  const handleSave = () => {
    setSaving(true);
    const userStorage = localStorage.getItem('user');
    let userId = '';

    if (userStorage) {
      const user = JSON.parse(userStorage);
      userId = user._id || '';
    }

    if (!userId) {
      setSaving(false);
      return;
    }

    const bankInfo = {
      userId,
      account_bank: selectedBank,
      account_number: accountNumber,
      account_name: accountName,
    };

    axios.post('https://ampcds.onrender.com/api/save-account', bankInfo)
      .then(response => {
        if (response.data && response.data.success) {
          setLinked(true);
          setSavedBankInfo(bankInfo);
          localStorage.setItem('bank_info', JSON.stringify(bankInfo));
        }
      })
      .catch(error => console.error('Error saving account:', error))
      .finally(() => setSaving(false));
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
      {!linked && (
        <div>
          <IconButton color="primary" onClick={() => setLinked(true)}>
            <AddCircleOutlineIcon style={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant="h4" style={{ fontWeight: 600, marginBottom: '16px' }}>Link Bank Account</Typography>
        </div>
      )}

      {linked && !saving && !savedBankInfo && (
        <div>
          <FormControl fullWidth margin="normal">
            <InputLabel>Bank</InputLabel>
            <Select
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
              disabled={loading}
            >
              {banks.map(bank => (
                <MenuItem key={bank.code} value={bank.code}>{bank.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Account Number"
            value={accountNumber}
            onChange={handleAccountNumberChange}
            disabled={loading}
          />
          {loading ? (
            <CircularProgress style={{ margin: '20px 0' }} />
          ) : (
            accountName && <Typography variant="h6" style={{ marginTop: '20px' }}>{accountName}</Typography>
          )}
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleSave}
            disabled={!accountName || loading}
            style={{ marginTop: '20px' }}
          >
            Save
          </Button>
        </div>
      )}

      {saving && (
        <CircularProgress style={{ margin: '20px 0' }} />
      )}

      {savedBankInfo && (
        <Card style={{ marginTop: '30px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: 600 }}>Saved Bank Account</Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}><strong>Bank:</strong> {banks.find(bank => bank.code === savedBankInfo.account_bank)?.name || 'Unknown'}</Typography>
            <Typography variant="body1" style={{ marginTop: '5px' }}><strong>Account Number:</strong> {savedBankInfo.account_number}</Typography>
            <Typography variant="body1" style={{ marginTop: '5px' }}><strong>Account Name:</strong> {savedBankInfo.account_name}</Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default LinkBankAccount;
