import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import Link and useNavigate from React Router
import { FaUserCircle } from 'react-icons/fa'; // Importing user circle icon from react-icons
import '../styles/CommentsComponent.css'; // Importing CSS for styling
import { debounce } from 'lodash'; // Import debounce function from lodash
import { RiSendPlane2Line } from 'react-icons/ri'; // Importing send icon from react-icons


const CommentsComponent = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const { jobId } = useParams(); // Retrieve jobId from URL parameters
  const commentsEndRef = useRef(null); // Reference for the end of comments list
  const navigate = useNavigate(); // Get navigate function from React Router


  // Define fetchComments outside of useEffect
  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://ampcds.onrender.com/api/comments/${jobId}`);
      if (response.ok) {
        const data = await response.json();
        setComments(data.comments);
        setError(''); // Clear any previous error
      } else {
        throw new Error('Failed to fetch comments');
      }
    } catch (error) {
      console.error(error);
      setError('Failed to fetch comments. Please try again.'); // Display user-friendly error message
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchComments = debounce(fetchComments, 500); // Debounce the fetchComments function

    debouncedFetchComments(); // Call the debounced function initially

    return () => {
      debouncedFetchComments.cancel(); // Cancel the debounce function on unmount
    };
  }, [jobId]);

  useEffect(() => {
    // Scroll to the end of comments when comments are updated
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);

  const handleSendClick = async () => {
    try {
      // Check if user key is present in localStorage
      const userKey = localStorage.getItem('user');
      if (!userKey) {
        // User key is not present, redirect to /login
        navigate('/login');
        return;
      }
  
      // User key is present, continue with posting comment
      const user = JSON.parse(userKey);
      const userId = user._id; // Retrieve userId from user object in localStorage
  
      if (!newComment.trim()) {
        setError('Comment cannot be empty');
        return;
      } else if (newComment.trim().length > 100) {
        setError('Comment is too long. Please keep it under 100 characters.');
        return;
      }
  
      setSending(true);
      const response = await fetch('https://ampcds.onrender.com/api/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobId,
          userId,
          comment: newComment,
        }),
      });
      if (response.ok) {
        fetchComments(); // Fetch comments after sending new comment
        setNewComment('');
        setError('');
      } else {
        throw new Error('Failed to send comment');
      }
    } catch (error) {
      console.error(error);
      setError('Failed to send comment');
    } finally {
      setSending(false);
    }
  };
  
  return (
    <div className="comments-container" style={{ height: '400px', overflowY: 'auto' }}>
      <h2 className="comments-heading">Comments</h2>
      {loading ? (
        <div className="loading-spinner"></div> // Add loading spinner here
      ) : comments.length === 0 ? (
        <p>No comments yet.</p>
      ) : (
        <ul className="comments-list">
          {comments.map((comment, index) => (
            <li key={comment.id || index} className="comment-item">
              <div className="comment-header">
                <FaUserCircle className="profile-icon" />
                <span style={{ marginLeft: '5px'}} className="comment-creator">{comment.creatorFullName}</span>
              </div>
              <p className="comment-text">{comment.text}</p>
              <div className="reply-text">Reply</div>
            </li>
          ))}
          <div ref={commentsEndRef}></div> {/* Reference for the end of comments */}
        </ul>
      )}
      <div className="comment-input-container">
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="comment-input"
          placeholder="Write a comment..."
        />
        <button onClick={handleSendClick} className="send" disabled={sending}>
          <RiSendPlane2Line className="send-icon" />
        </button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default CommentsComponent;
