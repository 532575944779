import React from 'react';

const AboutUs = () => {
  return (
    <div style={{ margin: '50px auto', maxWidth: '800px', textAlign: 'left', marginTop: '90px' }}>
      <h2>About iRunor</h2>
      <p>
        Welcome to iRunor, your go-to platform for connecting skilled service providers with clients in need of their expertise.
        Our mission is to revolutionize the way people access services by offering a convenient, flexible, and transparent platform that empowers both service providers and clients.
      </p>
      <h3>How It Works</h3>
      <p>
        iRunor operates on a pay-as-you-go basis, allowing clients to access a wide range of services without long-term commitments or hidden fees.
        Whether you need a plumber, electrician, tutor, personal trainer, or any other service provider, our platform makes it easy to find and hire the right professional for the job.
      </p>
      <h3>Key Features</h3>
      <ul style={{ textAlign: 'left' }}>
        <li>Wide Range of Services: Our platform offers a diverse selection of services, ensuring that clients can find assistance for any task or project.</li>
        <li>Verified Service Providers: Our Community user carefully vet all service providers to ensure they meet quality standards and deliver exceptional service, both for them and other users through transparent ratings and reviews.</li>
        <li>Flexible Booking: Clients can schedule services at their convenience, with options for same-day bookings and flexible appointment times.</li>
        <li>Transparent Pricing: We believe in transparency, which is why we provide upfront pricing information and no hidden fees.</li>
        <li>Secure Payments: Our platform utilizes secure payment gateways to protect clients' financial information and ensure safe transactions.</li>
        <li>Customer Support: Our dedicated customer support team is available to assist clients and service providers with any questions or concerns 24/7 from our social media handles.</li>
      </ul>
      <h3>Join Us Today!</h3>
      <p>
        Whether you're a skilled service provider looking to expand your client base or a client in need of reliable assistance, iRunor has you covered.
        Join our community today and experience the future of service provision!
      </p>
    </div>
  );
};

export default AboutUs;
