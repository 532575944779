import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FreeJob from '../components/FreeJob';
import { FaMapMarkerAlt, FaCheckCircle, FaRegCircle, FaUserCircle } from 'react-icons/fa';
import '../styles/JobList.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [newJobs, setNewJobs] = useState([]);
  const [isNewJobsLoading, setIsNewJobsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const localJobs = JSON.parse(localStorage.getItem('jobs')) || [];
    // Filter out jobs that are taken from local storage
    const filteredLocalJobs = localJobs.filter(job => !job.taken);
    setJobs(filteredLocalJobs);
    fetchNewJobs();
  }, []);

  const fetchNewJobs = () => {
    setIsNewJobsLoading(true);
    fetch('https://ampcds.onrender.com/jobs/get-job')
      .then(response => response.json())
      .then(data => {
        // Filter out jobs that are taken from the new jobs fetched from the backend
        const filteredJobs = data.filter(job => !job.taken);
        setNewJobs(filteredJobs);
        setIsNewJobsLoading(false);
        // Save only the jobs that are not taken in local storage
        localStorage.setItem('jobs', JSON.stringify(filteredJobs));
      })
      .catch(error => {
        console.error('Error fetching new job listings:', error);
        setIsNewJobsLoading(false);
      });
  };

  const handleViewButtonClick = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  const handleCallJob = async (jobId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { _id: userId, phoneNumber } = user;

    try {
      const response = await fetch(`https://ampcds.onrender.com/jobs/call-job-and-increase-count/${jobId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, phoneNumber }),
      });

      if (response.ok) {
        alert('Application sent successfully!');
        return true;
      } else {
        console.error('Failed to send application');
        return false;
      }
    } catch (error) {
      console.error('Error sending application:', error);
      return false;
    }
  };

  const renderMedia = (mediaUrl) => {
    if (!mediaUrl) return null;

    const isVideo = mediaUrl.match(/\.(mp4|webm|ogg)$/i);

    if (isVideo) {
      return (
        <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
          <source src={mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return <img src={mediaUrl} alt="Job media" style={{ maxWidth: '100%', borderRadius: '8px' }} />;
  };

  const JobDescription = ({ description }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div>
        <p 
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: isExpanded ? 'unset' : 2,
            cursor: 'pointer',
            color: '#444'
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {description}
        </p>
        {description.length > 100 && (
          <span
            style={{ color: '#1d72b8', cursor: 'pointer', fontSize: '14px' }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Show less' : 'Read more'}
          </span>
        )}
      </div>
    );
  };

  const JobCard = ({ job }) => (
    <div 
      key={job._id} 
      onClick={() => handleViewButtonClick(job._id)} 
      style={{ 
        backgroundColor: '#f4f4f4', 
        borderRadius: '12px', 
        padding: '20px',
        border: '1px solid #ddd',
        marginBottom: '15px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
        cursor: 'pointer', 
        transition: 'box-shadow 0.3s ease, transform 0.3s ease'
      }}
      onMouseEnter={e => e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)'}
      onMouseLeave={e => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)'}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {job.profileUrl ? (
          <img 
            src={job.profileUrl} 
            alt={`${job.creator}'s profile`} 
            style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} 
          />
        ) : (
          <FaUserCircle style={{ fontSize: '40px', color: '#666', marginRight: '10px' }} />
        )}
        <div>
          <h3 style={{ color: '#333', margin: 0 }}>{job.creator}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaMapMarkerAlt style={{ fontSize: '16px', color: '#666', marginRight: '5px' }} />
            <p style={{ fontSize: '14px', color: '#666', margin: 0 }}>{job.location}</p>
          </div>
        </div>
      </div>
      <JobDescription description={job.description} />
      {renderMedia(job.mediaUrl)}
      <div style={{
        backgroundColor: '#f8f8f8',
        border: '1px solid #ddd',
        width: '100%',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px'
      }}>
        <p style={{ fontSize: '16px', color: '#333' }}>
          {formatCurrency(job.maxBudget)}
        </p>
        <p style={{ fontSize: '14px', color: '#666' }}>
          <FaRegCircle 
            style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: '5px', color: '#1d72b8', cursor: 'pointer' }} 
            onClick={async (e) => {
              e.stopPropagation();
              const success = await handleCallJob(job._id);
              if (success) {
                e.currentTarget.replaceWith(<FaCheckCircle style={{ fontSize: '16px', verticalAlign: 'middle', marginRight: '5px', color: '#1d72b8' }} />);
              }
            }}
          />
          {job.callCount}
        </p>
      </div>
    </div>
  );

  return (
    <div style={{ width: '100%', textAlign: 'left', padding: '0 15px' }}>
      <FreeJob />

      <div style={{ marginBottom: '20px', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {jobs.map(job => (
            <JobCard key={job._id} job={job} />
          ))}
        </div>
      </div>

      {isNewJobsLoading && (
        <div className="loader" style={{ textAlign: 'center', fontSize: '18px', color: '#007bff', marginBottom: '20px' }}>
          Loading New Jobs...
        </div>
      )}

      <div style={{ marginBottom: '20px', width: '100%' }}>
        <h2 style={{ color: '#333', marginBottom: '15px' }}>New Jobs</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {newJobs.map(job => (
            <JobCard key={job._id} job={job} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobList;
