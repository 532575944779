import React, { useState } from 'react';
import axios from 'axios';

const SignUpForPromotions = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post('https://ampcds.onrender.com/api/collect-emails', {
                email,
            });
            if (response.status === 200) {
                setMessage('Thank you for joining. Look out for something special in your inbox.');
                setEmail(''); // Clear the input field
            } else {
                setMessage('Something went wrong. Please try again later.');
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setMessage('This email is already registered.');
            } else {
                setMessage('Error submitting your email. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Stay in the Know.</h2>
            <p style={styles.subText}>Sign up with your email to get more updates like this.</p>
            <form onSubmit={handleSubmit} style={styles.form}>
                <input
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    required
                />
                <button
                    type="submit"
                    style={{ ...styles.button, ...(isSubmitting && styles.buttonDisabled) }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                </button>
            </form>
            {message && <p style={styles.message}>{message}</p>}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    },
    header: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '10px',
        color: '#333',
    },
    subText: {
        fontSize: '16px',
        marginBottom: '20px',
        color: '#666',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
    },
    input: {
        padding: '12px 16px',
        fontSize: '16px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        marginBottom: '16px',
        outline: 'none',
        width: '90%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    button: {
        padding: '12px 16px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#0071e3',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
    },
    buttonDisabled: {
        backgroundColor: '#aaa',
    },
    message: {
        marginTop: '20px',
        fontSize: '14px',
        color: '#0071e3',
    },
};

export default SignUpForPromotions;
