import React from 'react';
import UserRanking from '../components/Ranking'; // Import the UserRanking component

const UserRankingPage = () => {
  return (
    <div>
      <UserRanking /> {/* Use the UserRanking component */}
    </div>
  );
};

export default UserRankingPage;
