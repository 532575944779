import React from 'react';
import UserProfile from '../components/Profile'; // Adjust the import path based on your project structure

const Profile = () => (
  <div>
    <UserProfile />
  </div>
);

export default Profile;
