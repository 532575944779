import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/Accounting.css';

const Accounting = () => {
  const [accountingData, setAccountingData] = useState(null);
  const [error, setError] = useState(null); // State for error handling
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    const fetchAccountingData = async () => {
      try {
        // Check if data is already stored in local storage
        const storedData = localStorage.getItem('subs');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setAccountingData(parsedData);
        } else {
          // Fetch data from server if not stored in local storage
          const response = await axios.get(`https://ampcds.onrender.com/api/auth/subscribed-users/${userId}`);

          setAccountingData(response.data);
          // Store data in local storage
          localStorage.setItem('subs', JSON.stringify(response.data));
        }
      } catch (error) {
        console.error('Error fetching accounting data:', error);
        setError('Error fetching accounting data. Please try again.'); // Set error state
      }
    };

    fetchAccountingData();
  }, [userId]);

  const handleViewJobs = () => {
    // Redirect to jobs page
    navigate(`/jobs/${userId}`);
  };

  return (
    <div className="container">
      
      <div className="content">
        {error && <div className="error">{error}</div>} {/* Display error message */}
        {accountingData && (
          <div className="accounting-info">
            {accountingData.map(user => (
              <div key={user._id}>
                <h3>{user.fullName}</h3>
                <p>{user.phoneNumber}</p>
                <p>Subscription Expiration Date: {user.subscriptionExpirationDate}</p>
                <p>Subscription Date: {user.subscriptionDate}</p>
                <div className="footer">
        <button className="view-jobs-btn" onClick={handleViewJobs}>View History</button>
      </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
    </div>
  );
};

export default Accounting;
