import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/JobOpenings.css"; // CSS for styling

const JobOpenings = () => {
  const { userId } = useParams(); // Get userId from the URL
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // Sending userId as a query parameter in the GET request
        const response = await axios.get(
          `https://ampcds.onrender.com/jobs/user-jobs?userId=${userId}`
        );
        setJobs(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, [userId]);

  const truncateDescription = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const handleJobClick = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };

  return (
    <div className="job-openings-container">
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {jobs.length === 0 ? (
            <div className="no-jobs">This user has 0 Openings</div>
          ) : (
            jobs.map((job) => (
              <div
                className="job-card"
                key={job._id}
                onClick={() => handleJobClick(job._id)}
              >
                <img src={job.mediaUrl} alt="Job" className="job-image" />
                <div className="job-details">
                  <div className="job-description">
                    {truncateDescription(job.description, 100)}
                  </div>
                  <div className="job-location">{job.location}</div>
                  <div className="job-budget">
                    Budget: ₦{job.minBudget} - ₦{job.maxBudget}
                  </div>
                  <div className="job-date">
                    Date: {new Date(job.date).toLocaleDateString()}
                  </div>
                  {job.taken ? (
                    <div className="job-completed">This job has been completed</div>
                  ) : (
                    <div className="job-available">This job is still Available</div>
                  )}
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default JobOpenings;
