// Signup.jsx
import React from 'react';
import TeamChat from '../components/Robin'; // Import the SignupForm component

const TeamChatPage = () => {

  return (
    <div >
      <TeamChat />
    </div>
  );
};

export default TeamChatPage;
