import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BusinessPlan = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            // Redirect to login if user data is not found, and pass the current path to redirect back after login
            const currentUrl = window.location.pathname;
            navigate(`/login?redirectUrl=${encodeURIComponent(currentUrl)}`);
        }
    }, [navigate]);

    const handleUpgrade = () => {
        const user = localStorage.getItem('user');
        if (!user) {
            // If no user, do not proceed with upgrade logic
            return;
        }

        // Continue with upgrade process
        console.log('Upgrade logic goes here');
    };

    const user = localStorage.getItem('user');
    let isSubscribed = false;
    let hasTeamKey = false;
    if (user) {
        const userData = JSON.parse(user);
        if (userData.isSubscribed) {
            isSubscribed = true;
        }
        if (userData.teamKey) {
            hasTeamKey = true;
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div>
                    <h2 style={styles.title}>Business Plan</h2>
                    <p style={styles.subtitle}>
                        Small and Medium Scale Businesses - Scale your business efficiently.
                    </p>
                </div>
                <p style={styles.price}>₦1560/month</p>
            </div>
            <div style={styles.features}>
                <ul>
                    <li>Instant SMS notifications for every job.</li>
                    <li>Unlimited calls to clients, no restrictions.</li>
                    <li>Clients credit your business directly for services rendered.</li>
                    <li>Access to your very own E-Office for streamlined operations.</li>
                    <li>Add up to 9 team members or servicemen.</li>
                    <li>Perfect for businesses looking to optimize resources and scale efficiently.</li>
                </ul>
                {isSubscribed && hasTeamKey ? (
                    <p style={styles.subscribedText}>
                        You're subscribed! Your business is set to conquer new heights with our optimized tools and services.
                    </p>
                ) : (
                    <a 
                        href="https://paystack.com/pay/Business-plan00" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={styles.link} 
                        onClick={handleUpgrade}
                    >
                        Upgrade Now! Power your business with unlimited calls and a team built for success.
                    </a>
                )}
            </div>
            <p style={styles.footerText}>
                By upgrading to the Business Plan, you'll unlock tools designed for growing businesses—get real-time job alerts, build a team, and streamline your services with an E-Office. Invest in your business today and see it thrive in tomorrow's market.
            </p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'left',
        margin: '50px auto',
        width: '80%',
        marginTop: '90px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginRight: '10px',
    },
    subtitle: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '10px',
    },
    price: {
        fontSize: '20px',
        color: '#333',
    },
    features: {
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    link: {
        display: 'block',
        fontSize: '16px',
        color: '#007bff',
        textDecoration: 'none',
        marginTop: '20px',
    },
    subscribedText: {
        fontSize: '16px',
        color: '#333',
        marginTop: '20px',
    },
    footerText: {
        fontSize: '14px',
        color: '#555',
        marginTop: '30px',
    },
};

export default BusinessPlan;
