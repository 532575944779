import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa'; // Import star icon for ratings

const SeeCallers = () => {
  const [callersData, setCallersData] = useState(null);
  const navigate = useNavigate();
  const { jobId } = useParams();

  useEffect(() => {
    const fetchCallersData = async () => {
      try {
        const response = await axios.get(`https://ampcds.onrender.com/jobs/callers/${jobId}`);
        console.log('Server Response:', response.data);
        setCallersData(response.data);
        localStorage.setItem('callers', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching callers data:', error);
      }
    };

    fetchCallersData();
  }, [jobId]);

  const handleCallBack = (phoneNumber) => {
    const isDesktop = window.innerWidth > 768;

    if (isDesktop) {
      alert(`Caller Phone Number: ${phoneNumber}`);
    } else {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  const handleCallerProfile = (callerId) => {
    navigate(`/user-profile/${callerId}`);
  };

  const renderReviews = (reviews) => {
    if (!reviews || reviews.length === 0) {
      return <p>No reviews available yet.</p>;
    }

    const lastReview = reviews[reviews.length - 1];
    return (
      <div style={styles.reviewSection}>
        <h4>Last Review</h4>
        <p>{lastReview}</p>
      </div>
    );
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.backLink}>
        <Link to="/my-jobs" style={styles.link}>
          <span>&#8592; Jobs you created.</span>
        </Link>
      </div>
      <h2 style={styles.title}>Callers List</h2>
      {callersData ? (
        callersData.map((caller, index) => (
          <div
            key={index}
            style={styles.callerCard}
            onClick={() => handleCallerProfile(caller.callerId)}
          >
            <h3 style={styles.callerName}>Full Name: {caller.fullName}</h3>
            <div style={styles.ratingsContainer}>
              {/* Displaying 5 stars for ratings */}
              {[...Array(5)].map((_, i) => (
                <FaStar key={i} style={styles.starIcon} />
              ))}
            </div>
            {renderReviews(caller.reviews)}
            <div style={styles.buttonContainer}>
              <button
                style={styles.callButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCallBack(caller.phoneNumber);
                }}
              >
                Call Back
              </button>
              <button
                style={styles.profileButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCallerProfile(caller.callerId);
                }}
              >
                View Profile
              </button>
            </div>
          </div>
        ))
      ) : (
        <p style={styles.loadingText}>Loading callers data...</p>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {
    marginTop: '90px',
    padding: '20px',
    textAlign: 'center',
    fontFamily: "'Arial', sans-serif",
    marginBottom: '90px',
  },
  backLink: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
    color: '#007BFF',
    fontSize: '1rem',
  },
  title: {
    fontSize: '1.8rem',
    color: '#333',
    marginBottom: '30px',
  },
  callerCard: {
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
    width: '90%',
    maxWidth: '600px',
    margin: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',
    textAlign: 'left',
  },
  callerName: {
    fontSize: '1.4rem',
    color: '#007BFF',
    marginBottom: '10px',
  },
  ratingsContainer: {
    display: 'flex',
    marginBottom: '15px',
  },
  starIcon: {
    color: '#FFD700', // Gold color for stars
    marginRight: '5px',
  },
  reviewSection: {
    fontSize: '1rem',
    color: '#555',
    fontStyle: 'italic',
    marginBottom: '15px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  callButton: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  profileButton: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  loadingText: {
    fontSize: '1.2rem',
    color: '#555',
    marginTop: '30px',
  },
};

export default SeeCallers;
