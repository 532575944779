import React from 'react';
import AppleStyleReview from '../components/Reviews'; // Assuming the component file is in the same directory

const ReviewsPage = () => {
  // Dummy data for reviews
  const reviews = [
    { username: 'John Doe', rating: 5, content: 'Excellent product!' },
    { username: 'Jane Smith', rating: 4, content: 'Good service.' },
    { username: 'Alice Johnson', rating: 3, content: 'Average experience.' },
  ];

  return (
    <div style={styles.container}>
      <h2>Reviews</h2>
      {reviews.map((review, index) => (
        <AppleStyleReview key={index} {...review} />
      ))}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
};

export default ReviewsPage;
