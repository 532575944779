import React from 'react';
import HomeComponent from '../components/HomeComponent'; // Assuming SeeCallers component is in the same directory

const Home = () => {
  return (
    <div>
      <HomeComponent />
      <div style={{ margin: '50px auto', width: '80%', textAlign: 'left', marginTop: '10px' }}> 
        
    </div>
    </div>
    
  );
};

export default Home;
