// Header.jsx
import React from 'react';

const Header = () => {
  return (
    <header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
      <img src="/favicon.ico" alt="irunor icon" style={{ height: '40px', width: '40px' }} />
    </header>
  );
};

export default Header;
