import React from 'react';
import ConfirmingSubscription from '../components/SubConfirm';

const SubConfirmation = () => {
  return (
    <div>
      <ConfirmingSubscription />
    </div>
  );
};

export default SubConfirmation;
