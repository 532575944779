import React from 'react';
import BlogDetails from '../components/LeadershipFocusDetails';

const LeadershipFocusDetailsPage = () => {
  return (
    <div>
      <BlogDetails />
    </div>
  );
};

export default LeadershipFocusDetailsPage;
