import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpgradeToUnlimitedCalls = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            // Redirect to login if user data is not found, and pass the current path to redirect back after login
            const currentUrl = window.location.pathname;
            navigate(`/login?redirectUrl=${encodeURIComponent(currentUrl)}`);
        }
    }, [navigate]);

    const handleUpgrade = () => {
        const user = localStorage.getItem('user');
        if (!user) {
            // If no user, do not proceed with upgrade logic
            return;
        }

        // Continue with upgrade process
        console.log('Upgrade logic goes here');
    };

    const user = localStorage.getItem('user');
    let isSubscribed = false;
    if (user) {
        const userData = JSON.parse(user);
        if (userData.isSubscribed && !userData.teamKey) {
            isSubscribed = true;
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div>
                    <h2 style={styles.title}>Individual Plan</h2>
                    <p style={styles.subtitle}>
                        Perfect for students, freelancers, and artisans looking to secure jobs effortlessly.
                    </p>
                </div>
                <p style={styles.price}>₦233/month</p>
            </div>
            <div style={styles.features}>
                <ul>
                    <li>Job Alert SMS notifications</li>
                    <li>Unlimited calls to clients</li>
                    <li>Clients credit you directly for your services</li>
                    <li>Exclusive placement on the home page for higher visibility</li>
                    <li>Render one service under this plan</li>
                </ul>
                {isSubscribed ? (
                    <p style={styles.subscribedText}>
                        You're already subscribed! You’re set to receive job alerts and unlimited calls this month. Don’t forget, if you don’t land a job in your first month, you’ll roll over to the next month—on us.
                    </p>
                ) : (
                    <a 
                        href="https://paystack.com/pay/irunor" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={styles.link} 
                        onClick={handleUpgrade}
                    >
                        Upgrade Now! Get featured on the home page and connect directly with clients today.
                    </a>
                )}
            </div>
            <p style={styles.footerText}>
                With the Individual Plan, you'll get unlimited calls, job alerts via SMS, and your services placed on our home page for top visibility. And remember, if you don't get hired this month, you'll roll over to the next at no extra cost. Elevate your career, one job at a time.
            </p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'left',
        margin: '50px auto',
        width: '80%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginRight: '10px',
    },
    subtitle: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '10px',
    },
    price: {
        fontSize: '20px',
        color: '#333',
    },
    features: {
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    link: {
        display: 'block',
        fontSize: '16px',
        color: '#007bff',
        textDecoration: 'none',
        marginTop: '20px',
    },
    subscribedText: {
        fontSize: '16px',
        color: '#333',
        marginTop: '20px',
        backgroundColor: '#e1f5fe',
        padding: '10px',
        borderRadius: '4px',
    },
    footerText: {
        fontSize: '14px',
        color: '#555',
        marginTop: '30px',
    },
};

export default UpgradeToUnlimitedCalls;
