import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaExchangeAlt, FaMoneyCheckAlt } from 'react-icons/fa';
import axios from 'axios';
import styled from 'styled-components';
import AccountOverview from './AccountOverview';
import TransfersAndPayments from './TransactionList';

const UserProfile = () => {
  const navigate = useNavigate();

  // Retrieve user data from local storage
  const storedUserData = localStorage.getItem('user');
  const userData = storedUserData ? JSON.parse(storedUserData) : {};
  const { fullName, phoneNumber, runorUnits } = userData;

  // State variables for the transfer process
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [amountToSend, setAmountToSend] = useState('');
  const [error, setError] = useState('');
  const [isTransferVisible, setIsTransferVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loader

  // Handle the Add Credits icon click
  const handleAddCredits = () => {
    navigate('/credits');
  };

  // Handle Withdrawals icon click
  const handleWithdrawals = () => {
    navigate('/withdrawals');
  };

  // Handle phone number input change
  const handlePhoneNumberChange = async (e) => {
    const value = e.target.value;
    setRecipientPhoneNumber(value);

    if (value.length === 11) {
      setIsLoading(true); // Show loader
      try {
        const response = await axios.get(`https://ampcds.onrender.com/api/payuser/${value}`);
        setRecipientName(response.data.fullName);
        setError('');
      } catch (err) {
        setError('Phone number not found.');
        setRecipientName('');
      } finally {
        setIsLoading(false); // Hide loader
      }
    } else {
      setRecipientName('');
    }
  };

  // Handle amount input change
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountToSend(value);

    if (parseInt(value) > runorUnits) {
      setError('Insufficient runorUnits.');
    } else {
      setError('');
    }
  };

  // Handle the transfer icon click
  const handleTransfer = async () => {
    if (!error && amountToSend && recipientPhoneNumber && recipientName) {
      try {
        await axios.post('https://ampcds.onrender.com/api/transfer', {
          senderPhoneNumber: phoneNumber,
          recipientPhoneNumber,
          amount: amountToSend,
          recipientName,
        });

        // Subtract the amount from the user's runorUnits and update local storage
        const updatedRunorUnits = runorUnits - parseInt(amountToSend, 10);
        const updatedUserData = { ...userData, runorUnits: updatedRunorUnits };
        localStorage.setItem('user', JSON.stringify(updatedUserData));

        alert('Transfer successful');

        // Refresh the page after successful transfer
        window.location.reload();

      } catch (err) {
        setError('Transfer failed.');
      }
    } else {
      setError('Please complete all fields.');
    }
  };

  return (
    <Container>
      <AccountOverview />
      <IconContainer>
        <IconWrapper onClick={handleAddCredits}>
          <FaPlus title="Add Credits" />
          <IconText>Topup Credits</IconText>
        </IconWrapper>
        <IconWrapper onClick={() => setIsTransferVisible(!isTransferVisible)}>
          <FaExchangeAlt title="Transfer Credits" />
          <IconText>Transfer Credits</IconText>
        </IconWrapper>
        <IconWrapper onClick={handleWithdrawals}>
          <FaMoneyCheckAlt title="Withdrawals" />
          <IconText>Withdraw</IconText>
        </IconWrapper>
      </IconContainer>

      {isTransferVisible && (
        <TransferSection>
          <Input
            type="tel"
            value={recipientPhoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter recipient phone number"
          />
          {isLoading ? (
            <Loader /> // Loader component
          ) : (
            recipientName && (
              <RecipientName>Recipient Name: {recipientName}</RecipientName>
            )
          )}
          <Input
            type="number"
            value={amountToSend}
            onChange={handleAmountChange}
            placeholder="Enter amount"
          />
          {error && <Error>{error}</Error>}
          <SubmitButton onClick={handleTransfer}>Submit</SubmitButton>
        </TransferSection>
      )}
      <TransfersAndPayments />
    </Container>
  );
};

// Styled components
const Container = styled.div`
  margin-top: 90px;
  width: 90%;
  margin-left: 10px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 33%;
  text-align: center;
`;

const IconText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: #555;
`;

const TransferSection = styled.div`
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const Input = styled.input`
  width: 90%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const RecipientName = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
`;

const Error = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 14px;
  background-color: #007aff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  transition: background-color 0.3s ease;
`;

const Loader = styled.div`
  width: 25px;
  height: 25px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007aff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 10px auto;
`;

// Keyframes for loader animation
const GlobalStyle = styled.div`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default UserProfile;
