// Signup.jsx
import React from 'react';
import Robin from '../components/Robin'; // Import the SignupForm component

const RobinPage = () => {

  return (
    <div >
      <Robin />
    </div>
  );
};

export default RobinPage;
