import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const AppleStyleReview = ({ username, rating, content }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<FontAwesomeIcon key={i} icon={faStar} style={{ color: '#ffc107' }} />);
      } else {
        stars.push(<FontAwesomeIcon key={i} icon={faStar} style={{ color: '#ccc' }} />);
      }
    }
    return stars;
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.username}>{username}</div>
        <div style={styles.stars}>{renderStars()}</div>
      </div>
      <div style={styles.content}>{content}</div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  username: {
    fontWeight: 'bold',
  },
  stars: {
    display: 'flex',
  },
  content: {
    color: '#333',
  },
};

export default AppleStyleReview;
