import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai'; // Import the close icon
import '../styles/FreeJob.css'; // Import the CSS file for styling

const FreeJob = () => {
  // State for the number of free jobs left
  const [freeJobsLeft, setFreeJobsLeft] = useState(0);
  // State for controlling the visibility of the component
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Retrieve user data from local storage
    const userData = localStorage.getItem('user');
    if (userData) {
      // Parse user data to extract freeJob and isSubscribed fields
      const userDataParsed = JSON.parse(userData);
      const freeJobs = userDataParsed.freeJob || 0;
      setFreeJobsLeft(freeJobs);
      
      // Check if user is subscribed, if so, hide the component
      if (userDataParsed.isSubscribed === true) {
        setIsVisible(false);
      }
    }
  }, []);

  // Function to close the FreeJob component
  const handleClose = () => {
    // Add logic to close the component
    console.log('Closing FreeJob component');
    setIsVisible(false); // Set isVisible state to false to hide the component
  };

  return (
    <>
      {isVisible && (
        <div className="free-job-container">
          <p className="free-job-text">You have {freeJobsLeft} of 5 free calls.</p>
          <AiOutlineClose className="close-icon" onClick={handleClose} />
        </div>
      )}
    </>
  );
};

export default FreeJob;
