import React, { useEffect, useState, useRef } from 'react';
import { RiArrowUpSLine } from 'react-icons/ri';
import '../styles/Robin.css';

const Robin = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(false); // Added state for first message sent
  const chatEndRef = useRef(null);

  // Scroll to the bottom of chat history when chatHistory changes
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userInput.trim() !== '') {
      setLoading(true);
      setHasError(false);
      setChatHistory((prevHistory) => [...prevHistory, { user: userInput }]);

      // Set firstMessageSent to true when user sends their first message
      if (!firstMessageSent) {
        setFirstMessageSent(true);
      }

      try {
        const response = await fetch('https://ampcds.onrender.com/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userInput }),
        });

        if (response.ok) {
          const data = await response.json();
          
          // Format bot response
          const formattedResponse = formatResponse(data.response);

          // Add bot response to chat history
          setChatHistory((prevHistory) => [...prevHistory, { bot: formattedResponse }]);
        } else {
          const errorData = await response.json();
          setChatHistory((prevHistory) => [...prevHistory, { bot: `Server Error: ${errorData.error}` }]);
          setHasError(true);
        }
      } catch (error) {
        setChatHistory((prevHistory) => [...prevHistory, { bot: `An error occurred: ${error.message}` }]);
        setHasError(true);
      }

      setLoading(false);
      setUserInput('');
    }
  };

  // Function to format the bot response
  const formatResponse = (response) => {
    return response
      .replace(/[*]/g, '•') // Replace asterisks with bullet points
      .replace(/\n/g, '<br/>'); // Replace line breaks with <br/> tags
  };

  // Function to handle the "Try Again" button click
  const handleTryAgain = async () => {
    // Reset error state
    setHasError(false);

    // Retrieve the last user message from chat history
    const lastUserMessage = chatHistory.length > 0 ? chatHistory[chatHistory.length - 1].user : '';

    if (lastUserMessage) {
      setLoading(true);

      try {
        const response = await fetch('https://ampcds.onrender.com/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userInput: lastUserMessage }),
        });

        if (response.ok) {
          const data = await response.json();
          
          // Format bot response
          const formattedResponse = formatResponse(data.response);

          // Add bot response to chat history
          setChatHistory((prevHistory) => [...prevHistory, { bot: formattedResponse }]);
        } else {
          const errorData = await response.json();
          setChatHistory((prevHistory) => [...prevHistory, { bot: `Server Error: ${errorData.error}` }]);
          setHasError(true);
        }
      } catch (error) {
        setChatHistory((prevHistory) => [...prevHistory, { bot: `An error occurred: ${error.message}` }]);
        setHasError(true);
      }

      setLoading(false);
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chat-history">
        {/* Display centered message if first message hasn't been sent */}
        {!firstMessageSent && (
          <div className="centered-message">
            How can I help you today?
          </div>
        )}

        {chatHistory.map((message, index) => (
          <div key={index} className={`message ${message.bot ? 'bot' : 'user'}`}>
            {message.bot ? (
              <span
                className="bot-message"
                dangerouslySetInnerHTML={{ __html: message.bot }}
              />
            ) : (
              <span className="user-message">{message.user}</span>
            )}
          </div>
        ))}

        {loading && <div className="loading-indicator">typing...</div>}
        
        {/* Reference for scrolling */}
        <div ref={chatEndRef} />
      </div>

      {/* Conditionally render the input form or "Try Again" button based on hasError */}
      {hasError ? (
        <button className="try-again-button" onClick={handleTryAgain}>
          Try Again
        </button>
      ) : (
        <form className="input-form" onSubmit={handleSubmit}>
          <input
            type="text"
            value={userInput}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className="input-field"
          />
          <button
            type="submit"
            className="send-button"
            disabled={loading}
          >
            <RiArrowUpSLine />
          </button>
        </form>
      )}
    </div>
  );
};

export default Robin;
