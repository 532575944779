// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import AccountOverview from '../components/AccountOverview';
import TransactionList from '../components/TransactionList';
import '../styles/Dashboard.css'; // Import the CSS file for styling

const Dashboard = () => {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [showTransactionList, setShowTransactionList] = useState(false); // Initialize as false

  useEffect(() => {
    // Fetch other necessary data (balance, transactions) based on user information
    // For now, setting dummy data
    setBalance(0);
    setTransactions([
      { id: 1, description: 'Purchase', amount: -50 },
      { id: 2, description: 'Deposit', amount: 100 },
      // Add more transactions as needed
    ]);
  }, []); // Empty dependency array ensures this effect runs once on component mount

  const toggleTransactionList = () => {
    setShowTransactionList((prev) => !prev);
  };

  return (
    <div className="dashboard-container">
      <AccountOverview balance={balance} />
      <div className="toggle-container">
        <label>{showTransactionList ? 'Hide' : 'Show'} Transaction History:</label>
        <label className="switch">
          <input type="checkbox" checked={showTransactionList} onChange={toggleTransactionList} />
          <span className="slider round"></span>
        </label>
      </div>
      {showTransactionList && <TransactionList transactions={transactions} />}
    </div>
  );
};

export default Dashboard;
