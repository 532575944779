import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook for URL parameters
import FormatText from './FormatText'; // Import the FormatText component
import axios from 'axios'; // Import axios for making HTTP requests
import SignUpForPromotions from '../components/SubmitEmail';

const BlogDetails = () => {
  const { blogId } = useParams(); // Extract blogId from URL parameters
  const [blogData, setBlogData] = useState(null); // State to store blog data
  const [loading, setLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`https://ampcds.onrender.com/jobs/blogs/${blogId}`); // Make GET request to fetch blog data
        setBlogData(response.data); // Set the blog data
      } catch (error) {
        setError('Error fetching blog data'); // Handle errors
      } finally {
        setLoading(false); // Set loading to false after request is complete
      }
    };

    fetchBlogData();
  }, [blogId]);

  if (loading) {
    return (
      <div style={{ margin: '50px auto', width: '90%', textAlign: 'center' }}>
        <h2>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ margin: '50px auto', width: '90%', textAlign: 'center' }}>
        <h2>{error}</h2>
        <p>Please go back and try again.</p>
      </div>
    );
  }

  if (!blogData) {
    return (
      <div style={{ margin: '50px auto', width: '90%', textAlign: 'center' }}>
        <h2>Error: Blog data not found</h2>
        <p>Please go back and select a blog again.</p>
      </div>
    );
  }

  return (
    <div style={{ margin: '50px auto', width: '90%', textAlign: 'left', marginLeft: '10px', marginTop: '90px' }}>
      <div>
        <h2 style={{ fontSize: '28px', fontWeight: 'bold', marginBottom: '20px', color: '#333', textAlign: 'left' }}>{blogData.title}</h2>
        <p style={{ fontSize: '16px', color: '#666', marginBottom: '30px' }}>By {blogData.createdBy}</p>
        <img src={blogData.picture} alt="Blog Cover" style={{ maxWidth: '100%', height: 'auto', marginBottom: '30px' }} />
        <FormatText text={blogData.body} /> {/* Use the FormatText component to parse and format the blog body */}
      </div>
      <div>
      <SignUpForPromotions />
      </div>
    </div>
  );
};

export default BlogDetails;
