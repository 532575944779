import React from 'react';
import '../styles/TermsAndConditions.css';

const TermsAndConditions = () => {
    console.log('Rendering Terms and Conditions');

  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to our global marketplace, a platform designed to connect you
        with the world. By accessing or using our platform, you agree to the
        following terms and conditions. These terms are in place to protect you,
        other users, and the integrity of our platform.
      </p>

      <h2>1. Platform Usage</h2>
      <p>
        Our platform serves as a meeting point for individuals to connect,
        collaborate, and create. We do not mediate, manage, or take
        responsibility for the transactions, communications, or agreements
        between users. You are solely responsible for the actions you take while
        using this platform.
      </p>

      <h2>2. User Responsibility</h2>
      <p>
        As a user, you are expected to engage in fair, lawful, and respectful
        behavior. You must ensure that your interactions, agreements, and
        transactions with others are legal and ethical. Any disputes or issues
        that arise are to be resolved between the parties involved, without
        involving the platform.
      </p>

      <h2>3. Content and Intellectual Property</h2>
      <p>
        You are responsible for the content you create, share, and distribute on
        our platform. This includes ensuring that your content does not infringe
        on any intellectual property rights or violate any laws. The platform
        reserves the right to remove content that violates these terms or is
        deemed harmful to the community.
      </p>

      <h2>4. Privacy and Data Protection</h2>
      <p>
        We value your privacy and are committed to protecting your personal
        information. However, you are responsible for safeguarding your account
        details and ensuring that your interactions on the platform do not
        compromise your privacy or that of others.
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        The platform is provided on an "as-is" basis. We do not guarantee the
        accuracy, reliability, or completeness of the services provided.
        Furthermore, we are not liable for any damages, losses, or disputes that
        arise from your use of the platform or interactions with other users.
      </p>

      <h2>6. Changes to Terms</h2>
      <p>
        We reserve the right to modify these terms and conditions at any time.
        Any changes will be effective immediately upon posting on the platform.
        It is your responsibility to review these terms regularly to stay
        informed of any updates.
      </p>

      <h2>7. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of the jurisdiction in which the platform operates. Any
        legal disputes will be handled in accordance with these laws.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        If you have any questions or concerns about these terms, please contact
        us through our <a href="/support">Support Page</a>. We’re here to help!
      </p>

      <p className="final-note">
        By using our platform, you acknowledge that you have read, understood,
        and agree to these terms and conditions. Let’s create a thriving, global
        community together—responsibly.
      </p>
    </div>
  );
};

export default TermsAndConditions;
