import React from 'react';

const UserRanking = () => {
  return (
    <div style={{ marginLeft: '20px', marginRight: '20px',  marginTop: '90px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>🌟 Introducing User Rankings! 🌟</h2>
      <p style={{ textAlign: 'justify' }}>
        We're thrilled to unveil our latest feature: User Rankings! 🎉 <br/><br/>

        At irunor, we value community engagement and recognize the exceptional contributions of our users. With this new functionality, we aim to celebrate and reward those who have gone above and beyond in creating opportunities and fostering collaboration within our platform. <br/><br/>

        🏆 **What's New?** <br/><br/>

        User Rankings provides a transparent and systematic approach to recognize individuals who have created the most jobs, making a significant impact on our community. By leveraging this feature, we can highlight the dedication and efforts of our top contributors, ultimately enriching the experience for everyone involved. <br/><br/>

        💡 **How It Works** <br/><br/>

        - **Ranking System**: Users are ranked based on the number of jobs they've created, showcasing their commitment to our platform. <br/>
        - **Rewards**: To express our gratitude, top-ranking users will receive special rewards, such as hiring credits, as a token of appreciation for their valuable contributions. <br/><br/>

        🚀 **What's Next?** <br/><br/>

        We're committed to continuous improvement and are actively listening to your feedback to ensure we're providing the best possible experience. As we continue to refine and enhance User Rankings, we'll keep you updated on new developments and features. <br/><br/>

        Thank you for being a part of our journey! Together, let's build a thriving community and empower individuals to achieve their goals. <br/><br/>

        Stay tuned for more exciting updates coming your way soon! ✨
      </p>
    </div>
  );
};

export default UserRanking;
