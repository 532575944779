import React, { useState, useEffect } from 'react';
import '../styles/AccountOverview.css';

const AccountOverview = () => {
  const [user, setUser] = useState(null);

  // Retrieve user information from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        // Parse user information securely
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing user information:', error);
      }
    }
  }, []);

  // Function to format the runorUnits as currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  // Check if user is loaded before accessing properties
  if (!user) {
    return <p>Loading user information...</p>;
  }

  return (
    <section
      className="account-overview-container"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderBottom: '1px solid #ccc',
        color: 'black',
        marginLeft: '0',
        marginRight: '0',
        position: 'relative',
        width: '100%',
      }}
    >
      <div
        className="account-section-1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <h2
          className="user-name"
          style={{
            textAlign: 'left',
            marginRight: '0px',
            marginTop: '0px',
            marginBottom: '2px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {user.fullName}
        </h2>
        <p
          className="account-number"
          style={{
            justifyContent: 'space-between',
            textAlign: 'left',
            marginLeft: '0px',
            marginTop: '2px',
            marginBottom: '2px',
          }}
        >
          {user.phoneNumber}
        </p>
      </div>

      <div
        className="account-section-2"
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          left: '0',
          marginTop: '70px'
        }}
      >
        <div className="balance" style={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          <strong><h2>{formatCurrency(user.runorUnits)}</h2></strong>
        </div>
      </div>
    </section>
  );
};

export default AccountOverview;
