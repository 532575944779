import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/UnitsComponent.css';

const UnitsComponent = ({ onClose }) => {
  const navigate = useNavigate();

  const handleTopUp = () => {
    navigate('/credits');
  };

  return (
    <div className="units-component">
      <p>You have insufficient units. Would you like to top up now?</p>
      <div className="button-container">
        <button className="close-button" onClick={onClose}>Close</button>
        <button className="top-up-button" onClick={handleTopUp}>Top-up</button>
      </div>
    </div>
  );
};

export default UnitsComponent;
