import React from 'react';
import PricingComponent from '../components/Credits'; // Adjust the import path as necessary

const CreditsPage = () => {
  return (
    <div>
      <h1>Credits</h1>
      {/* Other components or content for the Credits page */}
      
      <PricingComponent /> {/* Insert the PricingComponent here */}

      {/* Any other content or components */}
    </div>
  );
};

export default CreditsPage;
