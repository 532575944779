import React from 'react';
import SeeCallers from '../components/Callers'; // Assuming SeeCallers component is in the same directory

const CallersPage = () => {
  return (
    <div>
      <SeeCallers />
    </div>
  );
};

export default CallersPage;
