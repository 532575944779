import React, { useState } from 'react';

const HiringConfidence = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Get Hired by the World with Confidence.</h2>
      <p style={styles.text}>
        On irunor, every job posting comes with a guarantee. Employers match or exceed their maximum budget. Apply, fair compensation is a given.
      </p>
      {expanded && (
        <div>
          <p style={styles.text}>
            As an open-source community, we'll continue to deliver transparently. As you enjoy opportunities on our platform, do this:
          </p>
          <ul style={styles.list}>
            <li>Ask Questions</li>
            <li>Enjoy your right to privacy-  with your private name!</li>
            <li>Take jobs that moves you!</li>
          </ul>
          <p style={styles.text}>
            By taking these simple precautions, the world is yours. This is a world of possibilities, you're in control.
          </p>
          <p style={styles.signature}>irunor: You're hired by the world!</p>
        </div>
      )}
      <p onClick={toggleExpand} style={styles.readMore}>
        {expanded ? 'Read less...' : 'Read more...'}
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  header: {
    fontSize: '20px',
    marginBottom: '10px',
    color: '#333',
    textAlign: 'left',
  },
  text: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    paddingLeft: '20px',
    marginTop: '10px',
    color: '#555',
  },
  signature: {
    fontStyle: 'italic',
    color: '#333',
  },
  readMore: {
    color: '#007BFF',
    cursor: 'pointer',
    marginTop: '10px',
    textAlign: 'right',
  },
};

export default HiringConfidence;
