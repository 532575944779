import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import validator from 'validator';
import { FaUser, FaPhone, FaLock } from 'react-icons/fa'; // Import new icons
import '../styles/SignUpForm.css';

const SignupForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
    promotionalEmails: false,
  });

  const [formErrors, setFormErrors] = useState({
    fullName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    termsAccepted: '',
    promotionalEmails: '',
  });

  const [backendResponse, setBackendResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'confirmPassword') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: value !== formData.password ? 'Passwords do not match.' : '',
      }));
    }
  };

  const isFormValid = () => {
    const { password, confirmPassword, termsAccepted, promotionalEmails, ...rest } = formData;
    const isPasswordMatch = password === confirmPassword;

    return (
      isPasswordMatch &&
      termsAccepted &&
      promotionalEmails &&
      Object.values(rest).every((value) => value.trim() !== '')
    );
  };

  const handleHaveAccountClick = () => {
    navigate('/login');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const isValid = isFormValid();

      if (!isValid) {
        setFormErrors({
          fullName: !validator.isLength(formData.fullName, { min: 3, max: 50 })
            ? 'Full name must be between 3 and 50 characters.'
            : '',
          password: isValid ? '' : 'Passwords do not match.',
          confirmPassword: isValid ? '' : 'Passwords do not match.',
          phoneNumber: !validator.isMobilePhone(formData.phoneNumber)
            ? 'Please enter a valid phone number.'
            : '',
          termsAccepted: !formData.termsAccepted ? 'You must agree to the terms of service.' : '',
          promotionalEmails: !formData.promotionalEmails ? 'You must agree to receive promotional emails.' : '',
        });

        setBackendResponse('');
        return;
      }

      const response = await axios.post('https://ampcds.onrender.com/api/auth/signup', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      localStorage.setItem('signupResponse', JSON.stringify(response.data));

      setBackendResponse('Signup successful!');
      setFormErrors({});
      setFormData({
        fullName: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        termsAccepted: false,
        promotionalEmails: false,
      });

      navigate('/login');
    } catch (error) {
      console.error('Error during signup:', error);
      setBackendResponse(error.response ? error.response.data.error : 'An error occurred during signup.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <h2>Create New Account</h2>
      {Object.values(formErrors).map((error, index) => error && <p key={index} className="error-text">{error}</p>)}
      {backendResponse && <p className="success-text">{backendResponse}</p>}

      <div className="form-group">
        <FaUser className="icon user-icon" />
        <input
          type="text"
          id="fullName"
          name="fullName"
          placeholder="Enter Full Name"
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <FaPhone className="icon phone-icon" />
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Phone number"
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <FaLock className="icon lock-icon" />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <FaLock className="icon lock-icon" />
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm Password"
          onChange={handleChange}
          required
        />
      </div>
      
      <div className="form-group checkbox-group">
        <input
          type="checkbox"
          id="termsAccepted"
          name="termsAccepted"
          onChange={handleChange}
          required
        />
        <label htmlFor="termsAccepted">
          <span>
            When you create an account on irunor, You agree to the
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"> platform's terms of service</a>
            and
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"> privacy policy</a>.
          </span>
        </label>
      </div>
      <div className="form-group checkbox-group">
        <input
          type="checkbox"
          id="promotionalEmails"
          name="promotionalEmails"
          onChange={handleChange}
          required
        />
        <label htmlFor="promotionalEmails">
          <span>
            You agree to receive important promotional mails.
          </span>
        </label>
      </div>

      <div className="button-group">
        <button type="submit" className={`submit-button ${isFormValid() ? '' : 'disabled'}`} disabled={isLoading}>
          {isLoading ? 'Creating...' : 'Create'}
        </button>
        <button type="button" className="secondary-button" onClick={handleHaveAccountClick}>
          I have an Account
        </button>
      </div>
    </form>
  );
};

export default SignupForm;
