import React from 'react';

const BankAccountVerificationPage = () => {
  return (
    <div>
    </div>
  );
};

export default BankAccountVerificationPage;
