import React from 'react';
import LeadershipFocus from '../components/LeadershipFocus';

const LeadershipFocusPage = () => {
  return (
    <div>
      <LeadershipFocus />
    </div>
  );
};

export default LeadershipFocusPage;
