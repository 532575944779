import React from 'react';
import PhoneNumberVerification from '../components/PhoneNumberVerification';

const PhoneNumberVerificationPage = () => {
  return (
    <div>
      <PhoneNumberVerification />
    </div>
  );
};

export default PhoneNumberVerificationPage;
