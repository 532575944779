import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const WithdrawComponent = () => {
  const [step, setStep] = useState(1); // Step 1: Show intro, Step 2: Show input
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [success, setSuccess] = useState(false); // Success state
  const [error, setError] = useState(''); // Error state
  const [userId, setUserId] = useState(''); // User ID state
  const navigate = useNavigate();

  // Fetch user balance and userId from local storage
  useEffect(() => {
    const storedUserData = localStorage.getItem('user');
    const userData = storedUserData ? JSON.parse(storedUserData) : {};
    setBalance(userData.runorUnits || 0);
    setUserId(userData._id || ''); // Set the userId
  }, []);

  const handleProceed = () => {
    setStep(2);
  };

  const handleWithdraw = async () => {
    setIsLoading(true); // Start loading
    setError(''); // Reset error state

    // Check if the withdrawal amount includes the service fee (100 units)
    const totalAmount = parseInt(amount) + 100;

    if (totalAmount > balance) {
      setError(`Insufficient balance. Remember, a 100-unit service fee applies.`);
      setIsLoading(false);
      return;
    }

    // Log the userId to the console
    console.log('User ID:', userId);

    try {
      // Send the withdrawal request to the backend
      await axios.post('https://ampcds.onrender.com/api/withdraw', {
        amount: totalAmount,
        userId, // Include the userId in the data sent to the backend
      });

      setSuccess(true); // Show success message
      setTimeout(() => {
        navigate('/profile'); // Redirect to profile after 2 seconds
      }, 2000);
    } catch (err) {
      setError('Withdrawal failed. Please try again later.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div style={styles.container}>
      {step === 1 ? (
        <div style={styles.introSection}>
          <h2 style={styles.introText}>Withdraw to your linked Naira account</h2>
          <button style={styles.proceedButton} onClick={handleProceed}>
            Proceed
          </button>
        </div>
      ) : success ? (
        <div style={styles.successSection}>
          <h2 style={styles.successText}>Withdrawal Successful!</h2>
        </div>
      ) : (
        <div style={styles.withdrawSection}>
          <p style={styles.balanceText}>Balance: <strong>{balance}</strong> units</p>
          {error && <p style={styles.errorText}>{error}</p>}
          <p style={styles.infoText}>A 100-unit service fee (₦100 equivalent) applies to every withdrawal.</p>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount to withdraw"
            style={styles.input}
            disabled={isLoading} // Disable input while loading
          />
          <button
            style={{
              ...styles.withdrawButton,
              backgroundColor: !amount || parseInt(amount) > balance || isLoading
                ? '#ccc'
                : '#007aff',
            }}
            onClick={handleWithdraw}
            disabled={!amount || parseInt(amount) > balance || isLoading}
          >
            {isLoading ? (
              <div style={styles.loader}>
                <p style={styles.loaderText}>Processing...</p>
              </div> // Show loader while loading with text
            ) : (
              'Withdraw'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '400px',
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    marginTop: '90px',
    marginBottom: '100%',
  },
  introSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  introText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#333',
    marginBottom: '20px',
  },
  proceedButton: {
    backgroundColor: '#007aff',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    padding: '12px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  withdrawSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  balanceText: {
    fontSize: '16px',
    marginBottom: '15px',
    color: '#333',
  },
  infoText: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '15px',
  },
  errorText: {
    color: '#ff4d4f',
    fontSize: '14px',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '12px',
    marginBottom: '20px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  withdrawButton: {
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    padding: '12px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loaderText: {
    fontSize: '14px',
    color: '#007aff',
    marginTop: '10px',
  },
  successSection: {
    padding: '20px',
    textAlign: 'center',
  },
  successText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#007aff',
  },
};

export default WithdrawComponent;
