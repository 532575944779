// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <ul style={listStyle}>
                <li style={itemStyle}>
                    <Link to="/terms-and-conditions" style={linkStyle}>Terms and Conditions</Link>
                </li>
                <li style={itemStyle}>
                    <Link to="/upgrade-page" style={linkStyle}>Pricing</Link>
                </li>
                <li style={itemStyle}>
                    <Link to="/leadership-focus" style={linkStyle}>Leadership Focus 2024</Link>
                </li>
            </ul>
        </footer>
    );
};

const footerStyle = {
    backgroundColor: '#f8f9fa',
    padding: '10px 0',
    borderTop: '1px solid #e7e7e7',
    textAlign: 'center',
};

const listStyle = {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
};

const itemStyle = {
    margin: '0 15px',
};

const linkStyle = {
    textDecoration: 'none',
    color: '#007bff',
    fontWeight: '500',
    fontSize: '14px', // Reduced text size
};

export default Footer;

