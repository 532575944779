import React from "react";
import ProfileCard from "../components/GeneralProfile"; // Import the ProfileCard from GeneralProfile
import JobOpenings from "../components/Openings";

const GeneralProfilePage = () => {
  return (
    <div>
     
      <ProfileCard /> {/* Use the ProfileCard component */}
      <JobOpenings />
    </div>
  );
};

export default GeneralProfilePage;
